<template>
  <div>
    <Header />
    <Content />
  </div>
</template>

<script>
import Header from "@/components/Dashboard/Header.vue";
import Content from "@/components/Dashboard/Content.vue";
//import axios from "axios";
export default {
  name: "Dashboard",
  components: {
    Header,
    Content,
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  computed: {},
  methods: {
  },
};
</script>

<style lang="scss" scoped></style>
