var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tasks-stages-wrapper"
  }, [_c('v-col', {
    staticClass: "header"
  }, [_c('h5', [_vm._v(" " + _vm._s(_vm.$t("ActiveTasksAndEvents")) + " ")]), _c('div', [_c('span', {
    staticClass: "total-number"
  }, [_vm._v(" " + _vm._s(_vm.allTasksCounter) + " ")]), _c('span', {
    staticClass: "gaps10px",
    staticStyle: {
      "color": "#75787a"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Total")) + " ")])])]), _c('v-col', [_c('v-row', {
    staticClass: "content-small",
    staticStyle: {
      "gap": "4px"
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.$t("Generic")))]), _c('img', {
    staticClass: "point-separator",
    attrs: {
      "src": require("@/assets/images/•.svg"),
      "alt": ""
    }
  }), _c('p', [_vm._v(_vm._s(_vm.genericTasksCounter))])]), _c('v-row', {
    staticClass: "fill",
    staticStyle: {
      "height": "16px",
      "background-color": "#f4f5f7",
      "margin-top": "4px"
    }
  }, [_c('div', {
    staticStyle: {
      "background-color": "#75787a"
    },
    attrs: {
      "id": "generic"
    }
  })]), _c('v-row', {
    staticClass: "content-small",
    staticStyle: {
      "gap": "4px"
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.$t("TaskType.ShowingTheObject.long")))]), _c('img', {
    staticClass: "point-separator",
    attrs: {
      "src": require("@/assets/images/•.svg"),
      "alt": ""
    }
  }), _c('p', [_vm._v(_vm._s(_vm.clientVisitsCounter))])]), _c('v-row', {
    staticStyle: {
      "height": "16px",
      "background-color": "#F2FFE0",
      "margin-top": "4px"
    }
  }, [_c('div', {
    staticStyle: {
      "background-color": "#2B7B6D"
    },
    attrs: {
      "id": "clientVisit"
    }
  })]), _c('v-row', {
    staticClass: "content-small",
    staticStyle: {
      "gap": "4px"
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.$t("TaskType.Meeting.long")))]), _c('img', {
    staticClass: "point-separator",
    attrs: {
      "src": require("@/assets/images/•.svg"),
      "alt": ""
    }
  }), _c('p', [_vm._v(_vm._s(_vm.meetingsCounter))])]), _c('v-row', {
    staticStyle: {
      "height": "16px",
      "background-color": "#fff2f7",
      "margin-top": "4px"
    }
  }, [_c('div', {
    staticStyle: {
      "background-color": "#f81ca0"
    },
    attrs: {
      "id": "meetings"
    }
  })]), _c('v-row', {
    staticClass: "content-small",
    staticStyle: {
      "gap": "4px"
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.$t("TaskType.DeliveryOfPremises.long")))]), _c('img', {
    staticClass: "point-separator",
    attrs: {
      "src": require("@/assets/images/•.svg"),
      "alt": ""
    }
  }), _c('p', [_vm._v(_vm._s(_vm.deliveryOfPremisesCounter))])]), _c('v-row', {
    staticStyle: {
      "height": "16px",
      "background-color": "#FFFACC",
      "margin-top": "4px"
    }
  }, [_c('div', {
    staticStyle: {
      "background-color": "#FFD702"
    },
    attrs: {
      "id": "deliveryOfPremises"
    }
  })]), _c('v-row', {
    staticClass: "content-small",
    staticStyle: {
      "gap": "4px"
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.$t("TaskType.NotaryMeeting.long")))]), _c('img', {
    staticClass: "point-separator",
    attrs: {
      "src": require("@/assets/images/•.svg"),
      "alt": ""
    }
  }), _c('p', [_vm._v(_vm._s(_vm.notaryVisitsCounter))])]), _c('v-row', {
    staticStyle: {
      "height": "16px",
      "background-color": "#dbe8f7",
      "margin-top": "4px"
    }
  }, [_c('div', {
    staticStyle: {
      "background-color": "#4c8dd9"
    },
    attrs: {
      "id": "notary"
    }
  })]), _c('v-row', {
    staticClass: "content-small",
    staticStyle: {
      "gap": "4px"
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.$t("OpenHouses")))]), _c('img', {
    staticClass: "point-separator",
    attrs: {
      "src": require("@/assets/images/•.svg"),
      "alt": ""
    }
  }), _c('p', [_vm._v(_vm._s(_vm.openHouseCounter))])]), _c('v-row', {
    staticStyle: {
      "height": "16px",
      "background-color": "#d5cae0",
      "margin-top": "4px"
    }
  }, [_c('div', {
    staticStyle: {
      "background-color": "#7200E2"
    },
    attrs: {
      "id": "openHouse"
    }
  })])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }