var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overlay",
    attrs: {
      "id": "overlay-project"
    }
  }, [_c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closePanel,
      expression: "closePanel"
    }],
    staticClass: "modal-wrapper",
    attrs: {
      "id": "panel-wrapper-project"
    }
  }, [_c('div', {
    staticClass: "modal-header"
  }, [_c('v-row', {
    staticClass: "top-row"
  }, [_c('h4', [_vm._v(" " + _vm._s(_vm.$t("ProjectsCompleted")) + " ")])]), _c('v-divider', {
    staticClass: "divider"
  })], 1), _vm.deals ? _c('div', {
    staticClass: "modal-content"
  }, [_c('v-row', {
    staticClass: "month-clicked"
  }, [_c('h5', [_vm._v(_vm._s(_vm.monthClicked + " (" + _vm.count + ")"))])]), _vm.deals.length > 0 ? _c('span', [_c('v-row', {
    staticClass: "content-small item-row",
    staticStyle: {
      "color": "#75787a"
    }
  }, [_c('span', {
    staticClass: "address-row"
  }, [_vm._v(" " + _vm._s(_vm.$t("Address")) + " ")]), _c('span', {
    staticClass: "project-type"
  }, [_vm._v(" " + _vm._s(_vm.$t("Type")) + " ")]), _c('span', {
    staticClass: "project-contact-name"
  }, [_vm._v(" " + _vm._s(_vm.$t("User")) + " ")]), _c('span', {
    staticClass: "project-created-at"
  }, [_vm._v(" " + _vm._s(_vm.$t("Completed")) + " " + _vm._s(_vm.$t("Date").toLocaleLowerCase()) + " ")])]), _c('v-row', {
    staticClass: "separator"
  }), _vm._l(_vm.deals, function (project) {
    return _c('v-row', {
      key: project._id,
      staticClass: "content-small item-row"
    }, [_c('span', {
      staticClass: "project-name",
      on: {
        "click": function ($event) {
          return _vm.openProject(project._id);
        }
      }
    }, [_vm._v(" " + _vm._s(project.name) + " ")]), _c('span', {
      staticClass: "project-type"
    }, [_c('DealTypeContainer', {
      attrs: {
        "small-font": true,
        "deal-type": project.dealType
      }
    })], 1), _c('span', {
      staticClass: "project-contact-name"
    }, [_vm._v(" " + _vm._s(project.creator.name) + " ")]), _c('span', {
      staticClass: "project-created-at"
    }, [_vm._v(" " + _vm._s(project.doneDate) + " ")])]);
  })], 2) : _vm.deals.length === 0 ? _c('span', {
    staticClass: "subheading"
  }, [_vm._v(" " + _vm._s(_vm.$t("NoProjectsCompleted")) + " ")]) : _vm._e()], 1) : _c('div', {
    staticClass: "content-loading"
  }, [_c('div', {
    staticClass: "loader",
    staticStyle: {
      "position": "relative",
      "width": "70px",
      "height": "70px"
    }
  })])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }