var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.loaded ? _c('div', {
    staticClass: "loader"
  }) : _vm._e(), _vm.loaded ? _c('div', {
    staticClass: "content dashboard-wrapper"
  }, [_c('span', [_c('div', {
    staticClass: "dashboard-wrapper"
  }, [_vm.dealCounts ? _c('ProjectsStateChart', {
    attrs: {
      "dealCounts": _vm.dealCounts
    }
  }) : _vm._e(), _c('div', {
    staticClass: "main-container"
  }, [_c('div', [_c('v-row', [_c('h5', [_vm._v(_vm._s(_vm.$t("ProjectsCompleted")))])]), _c('v-row', {
    staticClass: "first-month",
    staticStyle: {
      "margin-top": "52px"
    },
    on: {
      "click": function ($event) {
        return _vm.openProjectPanel(_vm.lastThreeMonths[2]);
      }
    }
  }, [_c('p', {
    staticClass: "gaps10px",
    staticStyle: {
      "color": "#75787a",
      "width": "118px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.lastThreeMonths[2])) + " ")]), _c('p', {
    staticClass: "gaps10px font-size"
  }, [_vm._v(" " + _vm._s(_vm.prePreviousMonthDone) + " ")])]), _c('v-row', {
    staticClass: "first-month",
    staticStyle: {
      "margin-top": "4px"
    },
    on: {
      "click": function ($event) {
        return _vm.openProjectPanel(_vm.lastThreeMonths[1]);
      }
    }
  }, [_c('p', {
    staticClass: "gaps10px",
    staticStyle: {
      "color": "#75787a",
      "width": "118px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.lastThreeMonths[1])) + " ")]), _c('p', {
    staticClass: "gaps10px font-size"
  }, [_vm._v(" " + _vm._s(_vm.previousMonthDone) + " ")])]), _c('v-row', {
    staticClass: "first-month",
    staticStyle: {
      "margin-top": "4px"
    },
    on: {
      "click": function ($event) {
        return _vm.openProjectPanel(_vm.lastThreeMonths[0]);
      }
    }
  }, [_c('p', {
    staticClass: "gaps10px",
    staticStyle: {
      "color": "#75787a",
      "width": "118px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("CurrentMonth")) + " ")]), _c('p', {
    staticClass: "gaps10px font-size"
  }, [_vm._v(" " + _vm._s(_vm.currentDone) + " ")])])], 1)]), _c('div', {
    staticClass: "base-tasks-container"
  }, [_c('div', {
    staticClass: "base-tasks-header"
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "gap": "16px"
    }
  }, [_c('img', {
    attrs: {
      "height": "24",
      "width": "24",
      "src": require("@/assets/images/calendar.svg"),
      "alt": ""
    }
  }), _c('h5', [_vm._v(_vm._s(_vm.$t("TasksAndEvents")))])]), _c('BaseButton', {
    attrs: {
      "icon-left": 'add_16_16.svg',
      "size": "small",
      "label": _vm.$t('Add')
    },
    on: {
      "click": function ($event) {
        _vm.openTaskAddWidgetCounter++;
      }
    }
  })], 1), _c('BaseTasksAndEvents', {
    attrs: {
      "view": {
        name: 'dashboard'
      },
      "open-new-task-add-widget": _vm.openTaskAddWidgetCounter,
      "labels-and-order": {
        overDueTasks: {
          label: true,
          order: 2
        },
        noDueDateTasks: {
          label: true,
          order: 1
        },
        todayTasks: {
          label: true,
          order: 3
        },
        upcomingTasks: {
          label: true,
          order: 4
        }
      }
    },
    on: {
      "tasks": _vm.tasksUpdated
    }
  })], 1), _c('TasksStageChart', {
    attrs: {
      "tasks": _vm.tasks
    }
  })], 1)]), _c('span', [_c('span', {
    staticClass: "dashboard-wrapper",
    staticStyle: {
      "max-width": "570px"
    }
  }, [_c('SavedViews'), _vm.dealCounts ? _c('ProjectsChart', {
    attrs: {
      "projects": _vm.rightDeals
    }
  }) : _vm._e(), _vm.listings ? _c('ListingsChart', {
    attrs: {
      "listings": _vm.listings
    }
  }) : _vm._e()], 1)])]) : _vm._e(), _vm.openEditWidget ? _c('EditPanel', {
    attrs: {
      "clicked-task": _vm.clickedItem
    },
    on: {
      "closePanel": _vm.closeEditWidget,
      "updateKey": function ($event) {
        _vm.taskUpdater++;
        _vm.closeEditWidget;
      },
      "deleteTask": _vm.deleteTask
    }
  }) : _vm._e(), _vm.openProjectsPanel ? _c('ProjectsSidepanel', {
    attrs: {
      "month-clicked": _vm.selectedMonth
    },
    on: {
      "closed": function ($event) {
        _vm.openProjectsPanel = false;
      }
    }
  }) : _vm._e(), _vm.widgetOpen ? _c('CalendarWidget', {
    attrs: {
      "position": _vm.widgetPosition,
      "taskValue": _vm.taskValue
    },
    on: {
      "close": function ($event) {
        _vm.widgetOpen = false;
      },
      "update": function ($event) {
        return _vm.getTasks();
      }
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }