<template>
  <div class="saved-view-editor" v-click-outside="closeEditor">
    <div class="colors-row">
      <div
        class="view-color"
        :class="'view-color-' + color.color"
        v-for="(color, index) in colors"
        :key="index"
        @click="chooseColor(color)"
      >
        <img
          v-if="color.selected"
          src="../../assets/images/checkmark.svg"
          alt=""
        />
      </div>
    </div>
    <div class="icons-row">
      <div
        class="view-icon"
        v-bind:class="{
          'view-icon': !icon.selected,
          'view-icon icon-selected': icon.selected,
        }"
        v-for="(icon, index) in icons"
        :key="index"
        @click="chooseIcon(icon)"
      >
        <img :src="require(`@/assets/images/` + icon.icon)" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SavedViewEditor",
  props: ["view"],
  data() {
    return {
      colors: [
        { color: "grey", selected: false },
        { color: "dark_magenta", selected: false },
        { color: "magenta", selected: false },
        { color: "red", selected: false },
        { color: "orange", selected: false },
        { color: "yellow", selected: false },
        { color: "green", selected: false },
        { color: "dark_green", selected: false },
        { color: "turquoise", selected: false },
        { color: "blue", selected: false },
        { color: "dark_blue", selected: false },
        { color: "violet", selected: false },
      ],
      icons: [
        { icon: "projects.svg", selected: false },
        { icon: "objects.svg", selected: false },
        { icon: "clients.svg", selected: false },
        { icon: "home.svg", selected: false },
        { icon: "houses.svg", selected: false },
        { icon: "houses-1.svg", selected: false },
        { icon: "houses-2.svg", selected: false },
        { icon: "car.svg", selected: false },
        { icon: "shopping-cart.svg", selected: false },
        { icon: "briefcase.svg", selected: false },
        { icon: "flag.svg", selected: false },
        { icon: "language.svg", selected: false },
        { icon: "vip.svg", selected: false },
        { icon: "lightbulb.svg", selected: false },
        { icon: "lock.svg", selected: false },
        { icon: "pencil.svg", selected: false },
        { icon: "nature.svg", selected: false },
        { icon: "sun.svg", selected: false },
        { icon: "lightning.svg", selected: false },
        { icon: "favourite.svg", selected: false },
      ],
    };
  },
  created() {
    this.colors.forEach((color) => {
      if (this.view.color === color.color) {
        color.selected = true;
      }
    });
    this.icons.forEach((icon) => {
      if (this.view.icon === icon.icon) {
        icon.selected = true;
      }
    });
  },
  methods: {
    closeEditor() {
      this.$emit("closed");
    },
    chooseColor(color) {
      this.colors.forEach((col) => {
        col.selected = false;
      });
      color.selected = true;
      this.$emit("colorChosen", color.color);
    },
    chooseIcon(icon) {
      this.icons.forEach((ico) => {
        ico.selected = false;
      });
      icon.selected = true;
      console.log("tere23", icon);
      this.$emit("iconChosen", icon.icon);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../global_variables";

.saved-view-editor {
  position: absolute;
  top: -236px;
  right: 0;

  z-index: 4;
  cursor: default;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  gap: 16px;
  background: #ffffff;
  border: 1px solid #e6e8ec;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.03),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}
.colors-row,
.icons-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 126px;

  align-items: flex-start;
  padding: 0px;
  gap: 6px;
  background: #ffffff;
}
.view-color {
  width: 16px;
  height: 16px;
  cursor: pointer;
  background: #a30263;
  border-radius: 2px;
  img {
    filter: invert(99%) sepia(11%) saturate(0%) hue-rotate(214deg)
      brightness(115%) contrast(100%);
  }
}
.icons-row {
  justify-content: space-evenly;
  gap: 8px;
}
.view-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  &:hover {
    background-color: $grey;
  }
  img {
    width: 16px;
    height: 16px;
  }
}
.icon-selected {
  background-color: $grey;
}
.view-color-grey {
  background: $grey900;
}
.view-color-dark_magenta {
  background: $dark_magenta;
}
.view-color-magenta {
  background: $magenta;
}
.view-color-red {
  background: $red;
}
.view-color-orange {
  background: $orange;
}
.view-color-yellow {
  background: $yellow;
}
.view-color-green {
  background: $grey900;
}
.view-color-dark_green {
  background: $darkgreen;
}
.view-color-turquoise {
  background: $turquoise;
}
.view-color-blue {
  background: $blue;
}
.view-color-dark_blue {
  background: $dark_blue;
}
.view-color-violet {
  background: $violet;
}
</style>
