<template>
  <div class="projects-stages-wrapper">
    <v-col class="header">
      <span>
        <h5 style="margin-top: 4px">
          {{ $t("ProjectsByStage") }}
        </h5>
      </span>

      <span>
        <span class="total-number">
          {{ allDealsCounter }}
        </span>
        <span class="gaps10px" style="color: #75787a">
          {{ $t("Total") }}
        </span>
      </span>
    </v-col>

    <v-col>
      <v-row class="content-small" style="gap: 4px">
        <p>{{ $t("NewLeads") }}</p>
        <img class="point-separator" src="@/assets/images/•.svg" alt="" />
        <p>{{ qualifiedCounter }}</p>
      </v-row>
      <v-row class="fill" style="margin-top: 4px">
        <div id="qualified" style="background-color: #4c8dd9" />
      </v-row>

      <v-row class="content-small" style="gap: 4px">
        <p>{{ $t("NegotiatingRepresentation") }}</p>
        <img class="point-separator" src="@/assets/images/•.svg" alt="" />
        <p>{{ contactMadeCounter }}</p>
      </v-row>
      <v-row class="fill" style="margin-top: 4px">
        <div id="contactMade" style="background-color: #4c8dd9" />
      </v-row>

      <v-row class="content-small" style="gap: 4px">
        <p>{{ $t("Preparation") }}</p>
        <img class="point-separator" src="@/assets/images/•.svg" alt="" />
        <p>{{ preparationCounter }}</p>
      </v-row>
      <v-row class="fill" style="margin-top: 4px">
        <div id="preparation" style="background-color: #4c8dd9" />
      </v-row>

      <v-row class="content-small" style="gap: 4px">
        <p>{{ $t("ShowingAndAdvertising") }}</p>
        <img class="point-separator" src="@/assets/images/•.svg" alt="" />
        <p>{{ reviewCounter }}</p>
      </v-row>
      <v-row class="fill" style="margin-top: 4px">
        <div id="review" style="background-color: #4c8dd9" />
      </v-row>

      <v-row class="content-small" style="gap: 4px">
        <p>{{ $t("FinalizingDeal") }}</p>
        <img class="point-separator" src="@/assets/images/•.svg" alt="" />
        <p>{{ finishedCounter }}</p>
      </v-row>
      <v-row class="fill" style="margin-top: 4px">
        <div id="finished" style="background-color: #4c8dd9" />
      </v-row>

      <v-row class="content-small" style="gap: 4px">
        <p>{{ $t("DoneDone") }}</p>
        <img class="point-separator" src="@/assets/images/•.svg" alt="" />
        <p>{{ doneCounter }}</p>
      </v-row>
      <v-row class="fill-green" style="margin-top: 4px">
        <div id="done" style="background-color: #0aaf60" />
      </v-row>
    </v-col>
  </div>
</template>
<script>
export default {
  name: "ProjectsStateChart",
  components: {},
  props: ["dealCounts"],
  data() {
    return {
      qualifiedCounter: 0,
      contactMadeCounter: 0,
      preparationCounter: 0,
      reviewCounter: 0,
      finishedCounter: 0,
      doneCounter: 0,
      allDealsCounter: 0,
    };
  },
  created() {},
  mounted() {
    this.fillStacks();
  },
  watch: {},
  methods: {
    fillStacks() {
      if (this.dealCounts) {
        this.allDealsCounter = this.dealCounts['totalCount'] || 0;
        this.qualifiedCounter = this.dealCounts['qualified'] || 0;
        this.contactMadeCounter = this.dealCounts['contactMade'] || 0;
        this.preparationCounter = this.dealCounts['preparation'] || 0;
        this.reviewCounter = this.dealCounts['review'] || 0;
        this.finishedCounter = this.dealCounts['Finished'] || 0;
        this.doneCounter = this.dealCounts['Done'] || 0;
      }
      let qualifiedWidth = (this.qualifiedCounter / this.allDealsCounter) * 100;
      let contactMadeWidth =
        (this.contactMadeCounter / this.allDealsCounter) * 100;
      let preparationWidth =
        (this.preparationCounter / this.allDealsCounter) * 100;
      let reviewWidth = (this.reviewCounter / this.allDealsCounter) * 100;
      let finishedWidth = (this.finishedCounter / this.allDealsCounter) * 100;
      let doneWidth = (this.doneCounter / this.allDealsCounter) * 100;

      document.getElementById("qualified").style.width = qualifiedWidth + "%";
      document.getElementById("contactMade").style.width =
        contactMadeWidth + "%";
      document.getElementById("preparation").style.width =
          preparationWidth + "%";
      document.getElementById("review").style.width = reviewWidth + "%";
      document.getElementById("finished").style.width = finishedWidth + "%";
      document.getElementById("done").style.width = doneWidth + "%";
    },
  },
};
</script>

<style lang="scss" scoped>
.projects-stages-wrapper {
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  width: 520px;
  //height: 100%;
  display: flex;
  gap: 24px;

  .col {
    padding: 0;
  }
}

.total-number {
  font-weight: 800;
  font-size: 32px;
  line-height: 32px;
}
.header {
  display: grid;
  align-content: space-between;
}
.fill {
  height: 16px;
  background-color: #dbe8f7;
}
.fill-green {
  height: 16px;
  background-color: #f2ffe0;
}
</style>
