<template>
  <div class="projects-wrapper">
    <v-row style="gap: 16px">
      <img
          style="height: 24px; width: 24px"
          src="@/assets/images/building.svg"
      />
      <h5>
        {{ $t("NewProjects") }}
      </h5>
    </v-row>
    <v-row
        v-if="projects.length !== 0"
        class="content-small item-row"
        style="margin-top: 32px; color: #75787a"
    >
      <span style="width: 234px">
        {{ $t("Address") }}
      </span>
      <span style="width: 80px">
        {{ $t("Type") }}
      </span>
      <span style="width: 80px">
        {{ $t("User") }}
      </span>
      <span style="width: 80px">
        {{ $t("Created") }}
      </span>
    </v-row>
    <v-row
        v-if="projects.length !== 0"
        class="separator"
    />
    <v-row v-for="project in projects" :key="project._id" class="content-small item-row">
      <span style="width: 234px; cursor: pointer" @click="openProject(project._id)">
        {{ project.name }}
      </span>
      <span style="width: 80px">
        <DealTypeContainer :small-font="true" :deal-type="project.dealType"/>
      </span>
      <span style="width: 80px">
        {{ project.creator.name }}
      </span>
      <span style="width: 80px">
        {{ formatDate(project.createdAt) }}
      </span>
    </v-row>
    <v-row
        class="empty-list subheading"
        v-if="projects.length === 0"
    >
      {{ $t("NoNewProjects") }}
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import DealTypeContainer from "@/components/common/DealTypeContainer";
import {mapGetters} from "vuex";

export default {
  name: "ProjectsChart",
  components: {
    DealTypeContainer,
  },
  props: ["projects"],
  data() {
    return {};
  },
  created() {

  },
  computed: {
    ...mapGetters(["user"])
  },
  mounted() {
  },
  watch: {},
  methods: {
    formatDate(e) {
      return moment(e).format("DD.MM.YYYY");
    },
    openProject(projectId) {
      this.$router
          .push({
            name: "ProjectGeneral",
            params: {id: projectId},
          })
          .then(() => {
          });
    },
  },
};
</script>

<style lang="scss" scoped>

.empty-list {
  padding: 88px;
  justify-content: center;
}

.item-row {
  gap: 16px;
}

.projects-wrapper {
  background-color: #ffffff;
  width: 570px;
  border-radius: 8px;
  padding: 24px;
}

.separator {
  border-top: 1px solid;
  width: 100%;
  height: 1px;
}
</style>
