<template>
  <div class="overlay" id="overlay-project">
    <div
      v-click-outside="closePanel"
      id="panel-wrapper-project"
      class="modal-wrapper"
    >
      <div class="modal-header">
        <v-row class="top-row">
          <h4>
            {{ $t("ProjectsCompleted") }}
          </h4>
        </v-row>

        <v-divider class="divider" />
      </div>

      <div class="modal-content"
        v-if="deals"
      >
        <v-row class="month-clicked">
          <h5>{{ monthClicked + " (" + count + ")" }}</h5>
        </v-row>

        <span v-if="deals.length > 0">
          <v-row class="content-small item-row" style="color: #75787a">
          <span class="address-row">
            {{ $t("Address") }}
          </span>
          <span class="project-type">
            {{ $t("Type") }}
          </span>
          <span class="project-contact-name">
            {{ $t("User") }}
          </span>
          <span class="project-created-at">
            {{ $t("Completed") }} {{ $t("Date").toLocaleLowerCase() }}
          </span>
        </v-row>
          <v-row class="separator" />
          <v-row
            v-for="project in deals"
            :key="project._id"
            class="content-small item-row"
          >
            <span class="project-name" @click="openProject(project._id)">
              {{ project.name }}
            </span>
            <span class="project-type">
              <DealTypeContainer :small-font="true" :deal-type="project.dealType"/>
            </span>
            <span class="project-contact-name">
              {{ project.creator.name }}
            </span>
            <span class="project-created-at">
              {{ project.doneDate }}
            </span>
          </v-row>
        </span>
        <span v-else-if="deals.length === 0"
              class="subheading"
        >
          {{ $t("NoProjectsCompleted") }}
        </span>

      </div>
      <div class="content-loading"
           v-else
      >
        <div
            style="
              position: relative;
              width: 70px;
              height: 70px;
            "
            class="loader"
        />
      </div>

    </div>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import DealTypeContainer from "@/components/common/DealTypeContainer";
import {mapGetters} from "vuex";

export default {
  components: {
    DealTypeContainer,
  },
  name: "SidepanelAdd",
  props: ["monthClicked"],
  directives: {},
  data() {
    return {
      deals: null,
      count: 0,
    };
  },
  created() {
    this.getDeals();
  },
  mounted() {},
  watch: {},
  computed: {
    ...mapGetters(["user"])
  },
  methods: {
    closePanel() {
      this.$emit("closed");
    },
    openProject(projectId) {
      if (this.user.isAdmin || this.user._id === this.deals.find((deal) => deal._id === projectId).creator.userId) {
        this.$router
            .push({
              name: "ProjectGeneral",
              params: { id: projectId },
            })
            .then(() => {});
      } else {
        this.toastError(this.$t("noProjectAccess"));
      }
    },
    formatDate(e) {
      return moment(e).format("DD.MM.YYYY");
    },
    async getDeals() {
      const currentMonth = moment().month() + 1;
      let currentYear = moment().year();
      if (currentMonth === 1 && (this.monthClicked === "November" || this.monthClicked === "December")) {
        currentYear -= 1;
      } else if (currentMonth === 2 && (this.monthClicked === "December")) {
        currentYear -= 1;
      }
      const response = await axios.get(`/api/projects/completed/${this.monthClicked.toLowerCase()}/${currentYear}`);
      this.deals = response.data;
      this.count = this.deals.length;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/global_variables";

.content-loading {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.deal-type-lable {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #75787a !important;
}

.v-text-field--outlined .v-label {
  top: 20px;
}

::v-deep .delete {
  padding: 0 !important;
}
::v-deep .plus {
  display: none;
}

.calender-label {
  margin-top: -5px;
  left: auto;
  right: auto;
  position: absolute;
  font-size: 12px;
  font-family: Inter;
  background-color: white;
  margin-left: 12px;
}

.textarea:focus {
  border: 1px solid #e6e8ec;
  outline: none;
  border-radius: 8px;
}
.separator {
  border-top: 1px solid;
  width: 100%;
  height: 1px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 8;
}

::v-deep .mainMessage {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  //line-height: 20px;
  text-align: center;
  color: #000000;
}

::v-deep .beforeUpload .icon {
  height: 20px;
}

.image-uploader {
  //min-width: 896px;
  min-height: 96px;
  border: 1px dashed #c3c4c6;
  box-sizing: border-box;
  border-radius: 8px;
}

.project-type-selected-con-Sale {
  background: $lightgreen !important;
  color: $green !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-selected-con-LeaseOut {
  background: $lightpink !important;
  color: $magenta !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-selected-con-Purchase {
  background: $lightblue !important;
  color: $blue !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-selected-con-AquireLease {
  background: $beige !important;
  color: $orange !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-selected-con-Referral {
  background: $lightbrown !important;
  color: $brown !important;
  border: 0 !important;
  font-weight: bold !important;
}

.project-type-con {
  font-style: normal;
  font-weight: normal;
  border: 1px solid $grey500;
  box-sizing: border-box;
  border-radius: 8px;

  background: #fff;
  color: #000;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
}

.modal-wrapper {
  font-family: Inter !important;
  position: fixed;
  right: 0;
  top: 0%;
  height: 100vh;
  bottom: 0%;
  background-color: #ffffff;
  padding-top: 0px !important;
  z-index: 7;
  width: 560px;
  overflow: scroll;
  border: 1px solid #e6e8ec;
}

.modal-header {
  justify-content: flex-end;
  z-index: 111;
  padding-top: 40px;
  position: fixed;
  background: white;
  height: 97px;
  width: 560px;
}

.modal-content {
  font-size: 14px;
  padding: 110px 32px 140px 32px;
}

.modal-footer {
  justify-content: flex-end;
  padding-bottom: 64px;
  z-index: 110;
  position: fixed;
  background: white;
  bottom: 0;
  height: 104px;
  width: 560px;
}

.faded-title {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $grey950;
}

.buttons-row {
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 24px 50px 64px;
  height: 112px;
  font-family: Inter !important;
  color: #000000;
  width: 560px;
  background-color: white;

  .cancel {
    background-color: transparent !important;
    box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
    text-transform: none;
    padding: 12px;
    display: flex;
    align-items: center;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
}

::v-deep .theme--light.v-select .v-select__selections {
  color: #000000;
  line-height: 20px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  z-index: 55;
  left: 16px;
  cursor: pointer;
}

input::-webkit-datetime-edit {
  position: relative;
  left: 18px;
}

input::-webkit-datetime-edit-fields-wrapper {
  position: relative;
  left: 18px;
}

.birth-class {
  border: 1px solid #e6e8ec;
  border-radius: 8px;
  width: 49.2%;
  font-family: Inter;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  height: 56px;
  padding: 0px !important;

  &:focus {
    outline: none !important;
  }
}

.save-button {
  color: #ffffff;
  background: #000000;
  border: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  line-height: 16px;
  text-transform: none;
  width: 103px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.cancel-button {
  background-color: transparent !important;
  box-shadow: 0px 0 2px rgba(28, 14, 3, 0.3);
  text-transform: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  font-weight: 400 !important;
  line-height: 16px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}

.close-button {
  background: #f4f5f7;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
}

.textarea {
  width: 100%;
  height: 96px;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #e6e8ec;
  font-family: Inter;
  font-size: 16px;
  line-height: 20px;
}

.delete-button {
  margin-right: 168px;
  background-color: transparent !important;
  box-shadow: 0px 0 2px #ff1e24;
  text-transform: none;
  cursor: pointer;
  padding: 12px 16px;
  font-size: 14px;
  font-family: Inter;
  border-radius: 8px;
  color: #ff1e24;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 40px;
}

::v-deep .not-valid fieldset {
  border: 2px solid #ff1e24 !important;
}

.top-row {
  height: 24px;
  padding-left: 32px;
}
.divider {
  top: 97px;
  z-index: 111;
  position: fixed;
  width: 560px;
}
.month-clicked {
  padding-bottom: 12px;
  margin-top: 16px;
}
.item-row {
  margin-top: 32px;
}
.address-row {
  width: 234px;
}
.address-row {
  width: 234px;
}
.project-name {
  width: 234px;
  cursor: pointer;
}
.project-type {
  width: 100px;
}
.project-contact-name {
  width: 80px;
}
.project-created-at {
  width: 180px;
}

</style>
