<template>
  <div class="saved-views">
    <div class="saved-views-header">
      <img src="../../assets/images/bookmark.svg" alt="" />
      <h5>{{ $t("SavedViews") }}</h5>
    </div>
    <div class="saved-views-content">
      <div class="empty-list" v-if="savedViews.length === 0">
        <p class="subheading">{{ $t("NoSavedViews") }}</p>
      </div>
      <div
        class="saved-view"
        @click="goToView(view)"
        v-for="(view, index) in savedViews"
        :key="index"
      >
        <div
          class="saved-view-icon-wrapper"
          :class="'view-color-' + view.color"
        >
          <img :src="require(`@/assets/images/` + view.icon)" alt="" />
        </div>
        <div class="saved-view-title-wrapper">
          <div class="module-and-menu">
            <p class="module">{{ view.moduleName }}</p>
            <img
              @click.stop="view.editorOpen = !view.editorOpen"
              class="view-edit-menu"
              :src="require('@/assets/images/dot_menu.svg')"
              alt=""
            />
            <SavedViewEditor
              :view="view"
              @closed="view.editorOpen = false"
              @colorChosen="chooseColor(view, $event)"
              @iconChosen="chooseIcon(view, $event)"
              v-if="view.editorOpen"
            />
          </div>
          <p class="saved-view-title-text content-small content-semibold">
            {{ view.title }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SavedViewEditor from "@/components/Dashboard/SavedViewEditor";
export default {
  name: "SavedViews",
  components: {
    SavedViewEditor,
  },
  props: [],
  data() {
    return {
      savedViews: [],
    };
  },
  created() {
    this.setupViews();
  },
  computed: {
    ...mapGetters(["filters"]),
  },
  mounted() {},
  watch: {},
  methods: {
    ...mapActions(["setSavedViewColor", "setSavedViewIcon"]),
    setupViews() {
      for (const [key, value] of Object.entries(this.filters)) {
        if (key === "brokerId") continue;
        if (value.length < 2) continue;
        value.forEach((val, index) => {
          if (index === 0) return;
          let color = val.iconColor;
          let icon = val.icon;
          if (!color) {
            color = "grey";
          }
          if (!icon) {
            if (key === "listings") {
              icon = "objects.svg";
            }
            if (key === "contacts") {
              icon = "clients.svg";
            }
            if (key === "projects") {
              icon = "projects.svg";
            }
            if (key === "transactions" || key === "brokerTransactions") {
              icon = "transactions.svg";
            }
            if (key === "invoices") {
              icon = "invoice.svg";
            }
          }
          let savedView = {
            color,
            icon,
            moduleName: this.$t(key),
            module: key,
            filters: val,
            title: val.filterTitle,
            editorOpen: false,
            _id: val._id,
          };

          this.savedViews.push(savedView);
        });
      }
    },
    chooseColor(view, newColor) {
      view.color = newColor;
      this.setSavedViewColor({
        module: view.module,
        viewId: view._id,
        color: newColor,
      });
    },
    chooseIcon(view, newIcon) {
      view.icon = newIcon;
      this.setSavedViewIcon({
        module: view.module,
        viewId: view._id,
        icon: newIcon,
      });
    },
    async goToView(view) {
      if (view.editorOpen) {
        return;
      }
      let routeName = "";
      let routeParam = {
        savedViewId: view._id,
      };

      switch (view.module) {
        case "projects":
          routeName = "Deals";
          break;
        case "contacts":
          routeName = "Kliendiregister";
          break;
        case "listings":
          routeName = "Listings";
          break;
        case "transactions":
          routeName = "Transactions";
          routeParam.activeTab = "transactions"
          break;
        case "brokerTransactions":
          routeName = "Transactions";
          routeParam.activeTab = "brokers"
          break;
        case "invoices":
          routeName = "Invoices";
          break;
        default:
          return;
      }

      await this.$router.push({
        name: routeName,
        params: routeParam,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../global_variables";

.empty-list {
  padding: 24px;
}
.saved-views {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 24px;
  gap: 16px;
  background: #ffffff;
  border-radius: 8px;
  max-width: 570px;
}
.saved-views-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  img {
    width: 24px;
    height: 24px;
  }
}
.saved-views-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 8px;
}
.saved-view {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: nowrap;
  padding: 6px;
  flex-grow: 1;
  flex-basis: 0;
  //width: 156px;
  max-width: 168px;
  gap: 8px;
  border-radius: 8px;
  align-self: stretch;
  &:hover {
    cursor: pointer;
    background-color: #f4f5f7;
    .view-edit-menu {
      visibility: initial;
      display: flex;
    }
  }
}
.saved-view-icon-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  min-width: 48px;
  max-width: 48px;
  min-height: 48px;
  max-height: 48px;
  border-radius: 6px;
  img {
    width: 24px;
    height: 24px;
    filter: invert(99%) sepia(11%) saturate(0%) hue-rotate(214deg)
      brightness(115%) contrast(100%);
  }
}
.saved-view-title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  .module {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
    color: #75787a;
  }
  .saved-view-title-text {
    line-height: 16px;
    width: 100%;
  }
}
.module-and-menu {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
  img {
    width: 16px;
    height: 16px;
  }
}
.view-edit-menu {
  visibility: hidden;
  &:hover {
    background: $grey500;
    border-radius: 4px;
  }
}

.view-color-grey {
  background: $grey900;
}
.view-color-dark_magenta {
  background: $dark_magenta;
}
.view-color-magenta {
  background: $magenta;
}
.view-color-red {
  background: $red;
}
.view-color-orange {
  background: $orange;
}
.view-color-yellow {
  background: $yellow;
}
.view-color-green {
  background: $grey900;
}
.view-color-dark_green {
  background: $darkgreen;
}
.view-color-turquoise {
  background: $turquoise;
}
.view-color-blue {
  background: $blue;
}
.view-color-dark_blue {
  background: $dark_blue;
}
.view-color-violet {
  background: $violet;
}
</style>
