<template>
  <div>
    <div v-if="!loaded" class="loader"></div>
    <div v-if="loaded" class="content dashboard-wrapper">
      <span>
        <div class="dashboard-wrapper">
          <ProjectsStateChart
              v-if="dealCounts"
              :dealCounts="dealCounts"
          />
          <div
              class="main-container"
          >
            <div>
              <v-row>
                <h5>{{ $t("ProjectsCompleted") }}</h5>
              </v-row>
              <v-row
                  class="first-month"
                  @click="openProjectPanel(lastThreeMonths[2])"
                  style="margin-top: 52px"
              >
                <p class="gaps10px" style="color: #75787a; width: 118px">
                  {{ $t(lastThreeMonths[2]) }}
                </p>

                <p class="gaps10px font-size">
                  {{ prePreviousMonthDone }}
                </p>
              </v-row>
              <v-row
                  @click="openProjectPanel(lastThreeMonths[1])"
                  class="first-month"
                  style="margin-top: 4px"
              >
                <p class="gaps10px" style="color: #75787a; width: 118px">
                  {{ $t(lastThreeMonths[1]) }}
                </p>
                <p class="gaps10px font-size">
                  {{ previousMonthDone }}

                </p>
              </v-row>
              <v-row
                  @click="openProjectPanel(lastThreeMonths[0])"
                  class="first-month"
                  style="margin-top: 4px"
              >
                <p class="gaps10px" style="color: #75787a; width: 118px">
                  {{ $t("CurrentMonth") }}
                </p>
                <p class="gaps10px font-size">
                  {{ currentDone }}
                </p>
              </v-row>
            </div>
          </div>
          <div class="base-tasks-container">
            <div class="base-tasks-header">
              <div style="display: flex; gap: 16px">
                <img
                    height="24"
                    width="24"
                    src="@/assets/images/calendar.svg"
                    alt=""
                />
                <h5>{{ $t("TasksAndEvents") }}</h5>
              </div>
              <BaseButton
                  @click="openTaskAddWidgetCounter++"
                  :icon-left="'add_16_16.svg'"
                  size="small"
                  :label="$t('Add')"
              />
            </div>
            <BaseTasksAndEvents
                @tasks="tasksUpdated"
                :view="{ name: 'dashboard' }"
                :open-new-task-add-widget="openTaskAddWidgetCounter"
                :labels-and-order="{
                overDueTasks: {
                  label: true,
                  order: 2,
                },
                noDueDateTasks: {
                  label: true,
                  order: 1,
                },
                todayTasks: {
                  label: true,
                  order: 3,
                },
                upcomingTasks: {
                  label: true,
                  order: 4,
                },
              }"
            />
          </div>
          <TasksStageChart :tasks="tasks"/>
        </div>
      </span>

      <span>
        <span class="dashboard-wrapper" style="max-width: 570px">
          <SavedViews/>
          <ProjectsChart
              v-if="dealCounts"
              :projects="rightDeals"
          />
          <ListingsChart v-if="listings" :listings="listings"/>
        </span>
      </span>
    </div>
    <EditPanel
        v-if="openEditWidget"
        @closePanel="closeEditWidget"
        @updateKey="
        taskUpdater++;
        closeEditWidget;
      "
        @deleteTask="deleteTask"
        :clicked-task="clickedItem"
    />
    <ProjectsSidepanel
        v-if="openProjectsPanel"
        @closed="openProjectsPanel = false"
        :month-clicked="selectedMonth"
    />
    <CalendarWidget
        v-if="widgetOpen"
        @close="widgetOpen = false"
        @update="getTasks()"
        :position="widgetPosition"
        :taskValue="taskValue"
    />
  </div>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";
import EditPanel from "@/components/Calendar/EditPanel";
import ProjectsSidepanel from "@/components/Dashboard/ProjectsSidepanel.vue";
import TasksStageChart from "@/components/Dashboard/Charts/TasksStageChart";
import ProjectsStateChart from "@/components/Dashboard/Charts/ProjectsStateChart";
import ListingsChart from "@/components/Dashboard/Charts/ListingsChart";
import ProjectsChart from "@/components/Dashboard/Charts/ProjectsChart";
import CalendarWidget from "@/components/Tasks/CalendarWidget";
import SavedViews from "@/components/Dashboard/SavedViews";
import BaseTasksAndEvents from "@/components/common/BaseTasksAndEvents";
import BaseButton from "@/components/common/BaseButton";

export default {
  name: "App",
  components: {
    BaseButton,
    BaseTasksAndEvents,
    EditPanel,
    ProjectsSidepanel,
    TasksStageChart,
    ProjectsStateChart,
    ListingsChart,
    ProjectsChart,
    CalendarWidget,
    SavedViews,
  },
  data() {
    return {
      openTaskAddWidgetCounter: 0,
      taskUpdater: 0,
      addedTask: null,
      taskValue: "",
      openProjectsPanel: false,
      currentDone: 0,
      previousMonthDone: 0,
      prePreviousMonthDone: 0,
      dealCounts: null,
      lastThreeMonths: [],
      widgetOpen: false,
      openEditWidget: false,
      clickedItem: null,
      widgetPosition: {},
      selectedMonth: null,
      taskWidgetUpdater: 0,
      loaded: false,
      deals: null,
      rightDeals: [],
      listings: null,
      tasks: [],
    };
  },
  async created() {
    this.lastThreeMonth();

    await Promise.all([this.getDeals(), this.getTasks(), this.getListings()]);
    this.loaded = true;

  },
  mounted() {
  },
  watch: {},
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    async deleteTask(taskId) {
      let msToken = null;
      if (this.user.microsoft) {
        const access = await this.getMsalAccessToken();
        if (access) {
          msToken = access.accessToken;
        }
      }
      const res = await axios.post(`/api/task/delete/${taskId}`, {
        accessToken: msToken,
      });

      if (res.status === 200) {
        this.tasks = this.tasks.filter((task) => task._id !== taskId);
        this.taskUpdater++;
        this.toastSuccess(this.$t("Deleted"));
      } else {
        this.toastError(this.$t("ErrorDeleting"));
      }
    },
    tasksUpdated(value) {
      this.tasks = value;
    },
    async getTasks() {
      // Todo tasks dates sorting in backend
      const res = await axios.get(`api/tasks/active/assignee/${this.user._id}`);
      if (res.status === 200) {
        this.tasks = res.data;
        this.taskUpdater++;
      } else {
        console.error("error", res);
      }
    },
    async getListings() {
      let filter = {
        limit: 5,
        fields: {_id: 1, listingName: 1, creator: 1, createdAt: 1},
      };

      const response = await axios.post("/api/listings/custom-filter", filter);

      this.listings = response.data;
    },
    async getDeals() {
      const response = await axios.get("/api/project/dashboard");
      if (response && response.data) {
        if (response.data['completedCounts'][0]) {
          this.currentDone = response.data['completedCounts'][0]['currentMonthCompleted'];
          this.previousMonthDone = response.data['completedCounts'][0]['previousMonthCompleted'];
          this.prePreviousMonthDone = response.data['completedCounts'][0]['prePreviousMonthCompleted'];
        }

        this.dealCounts = {
          totalCount: response.data?.totalCount?.[0]?.count || 0
        };

        response.data['pipelineSteps'].forEach((step) => {
          this.dealCounts[step._id] = step.count;
        })

        this.rightDeals = response.data['recentItems']
      }


      /*


      this.deals = response.data.deals;
      this.deals.forEach((el) => {
        if (el.creator) {
          this.rightDeals.push(el);
        }
      });
      let months = {
        January: "01",
        Jaanuar: "01",
        February: "02",
        Veebruar: "02",
        March: "03",
        Märts: "03",
        April: "04",
        Aprill: "04",
        May: "05",
        Mai: "05",
        June: "06",
        Juuni: "06",
        July: "07",
        Juuli: "07",
        August: "08",
        September: "09",
        October: "10",
        Oktoober: "10",
        November: "11",
        December: "12",
        Detsember: "12",
      };
      let prepreviousMonth = months[this.lastThreeMonths[2]];
      let previousMonth = months[this.lastThreeMonths[1]];
      let currentDone = months[this.lastThreeMonths[0]];
      this.deals.forEach((deal) => {
        if (deal.doneDate && deal.pipelineStep === "Done") {
          if (deal.doneDate.includes("." + currentDone)) {
            this.currentDone++;
          } else if (deal.doneDate.includes("." + previousMonth)) {
            this.januaryDone++;
          } else if (deal.doneDate.includes("." + prepreviousMonth)) {
            this.decemberDone++;
          }
        }
      });

       */
      this.loaded = true;
    },
    openProjectPanel(month) {
      this.selectedMonth = month;
      this.openProjectsPanel = true;
    },
    lastThreeMonth() {
      let theMonths = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      let now = new Date();
      for (let i = 0; i > -3; i--) {
        let future = new Date(now.getFullYear(), now.getMonth() + i, 1);
        let month = theMonths[future.getMonth()];
        this.lastThreeMonths.push(month);
      }
    },
    closeEditWidget() {
      this.openEditWidget = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  max-width: 798px;
}

.main-container {
  background-color: #ffffff;
  width: 254px;
  height: 276px;
  border-radius: 8px;
  padding: 24px;
}

.first-month {
  background-color: #fafafa;
  width: 206px;
  height: 48px;
  border-radius: 8px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-left: 12px;
  padding-bottom: 8px;
  padding-right: 12px;
  padding-top: 8px;
  cursor: pointer;
}

.font-size {
  padding-left: 4px;
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 32px;
}

.content {
  background-color: #f4f5f7;
  padding: 24px 8px 72px 104px;
  max-width: 1504px;
}

.edit-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 6px;
  height: 24px;
  gap: 6px;
  border: 1px solid #e6e8ec;
  box-sizing: border-box;
  border-radius: 8px;
  max-width: 58px;
}

.edit-button:hover {
  background-color: #e7e7e7;
  cursor: pointer;
}

.edit-button-text {
  font-size: 12px;
}

.loader {
  margin-top: 24px;
  position: absolute;
  top: 30%;
  left: 50%;
}

.base-tasks-container {
  background: white;
  padding: 24px;
  border-radius: 8px;
  height: 421px;
  width: 387px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.base-tasks-header {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
</style>
