var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeEditor,
      expression: "closeEditor"
    }],
    staticClass: "saved-view-editor"
  }, [_c('div', {
    staticClass: "colors-row"
  }, _vm._l(_vm.colors, function (color, index) {
    return _c('div', {
      key: index,
      staticClass: "view-color",
      class: 'view-color-' + color.color,
      on: {
        "click": function ($event) {
          return _vm.chooseColor(color);
        }
      }
    }, [color.selected ? _c('img', {
      attrs: {
        "src": require("../../assets/images/checkmark.svg"),
        "alt": ""
      }
    }) : _vm._e()]);
  }), 0), _c('div', {
    staticClass: "icons-row"
  }, _vm._l(_vm.icons, function (icon, index) {
    return _c('div', {
      key: index,
      staticClass: "view-icon",
      class: {
        'view-icon': !icon.selected,
        'view-icon icon-selected': icon.selected
      },
      on: {
        "click": function ($event) {
          return _vm.chooseIcon(icon);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require(`@/assets/images/` + icon.icon),
        "alt": ""
      }
    })]);
  }), 0)]);

}
var staticRenderFns = []

export { render, staticRenderFns }