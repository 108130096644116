<template>
  <div class="tasks-stages-wrapper">
    <v-col class="header">
      <h5>
        {{ $t("ActiveTasksAndEvents") }}
      </h5>
      <div>
        <span class="total-number">
          {{ allTasksCounter }}
        </span>
        <span class="gaps10px" style="color: #75787a">
          {{ $t("Total") }}
        </span>
      </div>
    </v-col>

    <v-col>
      <v-row class="content-small" style="gap: 4px">
        <p>{{ $t("Generic") }}</p>
        <img class="point-separator" src="@/assets/images/•.svg" alt="" />
        <p>{{ genericTasksCounter }}</p>
      </v-row>
      <v-row
        class="fill"
        style="height: 16px; background-color: #f4f5f7; margin-top: 4px"
      >
        <div id="generic" style="background-color: #75787a" />
      </v-row>

      <v-row class="content-small" style="gap: 4px">
        <p>{{ $t("TaskType.ShowingTheObject.long") }}</p>
        <img class="point-separator" src="@/assets/images/•.svg" alt="" />
        <p>{{ clientVisitsCounter }}</p>
      </v-row>
      <v-row style="height: 16px; background-color: #F2FFE0; margin-top: 4px">
        <div id="clientVisit" style="background-color: #2B7B6D" />
      </v-row>

      <v-row class="content-small" style="gap: 4px">
        <p>{{ $t("TaskType.Meeting.long") }}</p>
        <img class="point-separator" src="@/assets/images/•.svg" alt="" />
        <p>{{ meetingsCounter }}</p>
      </v-row>
      <v-row style="height: 16px; background-color: #fff2f7; margin-top: 4px">
        <div id="meetings" style="background-color: #f81ca0" />
      </v-row>

      <v-row class="content-small" style="gap: 4px">
        <p>{{ $t("TaskType.DeliveryOfPremises.long") }}</p>
        <img class="point-separator" src="@/assets/images/•.svg" alt="" />
        <p>{{ deliveryOfPremisesCounter }}</p>
      </v-row>
      <v-row style="height: 16px; background-color: #FFFACC; margin-top: 4px">
        <div id="deliveryOfPremises" style="background-color: #FFD702" />
      </v-row>

      <v-row class="content-small" style="gap: 4px">
        <p>{{ $t("TaskType.NotaryMeeting.long") }}</p>
        <img class="point-separator" src="@/assets/images/•.svg" alt="" />
        <p>{{ notaryVisitsCounter }}</p>
      </v-row>
      <v-row style="height: 16px; background-color: #dbe8f7; margin-top: 4px">
        <div id="notary" style="background-color: #4c8dd9"></div>
      </v-row>

      <v-row class="content-small" style="gap: 4px">
        <p>{{ $t("OpenHouses") }}</p>
        <img class="point-separator" src="@/assets/images/•.svg" alt="" />
        <p>{{ openHouseCounter }}</p>
      </v-row>
      <v-row style="height: 16px; background-color: #d5cae0; margin-top: 4px">
        <div id="openHouse" style="background-color: #7200E2"></div>
      </v-row>

    </v-col>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "TasksStagesWidget",
  components: {},
  props: ["tasksUpdater", "tasks"],
  data() {
    return {
      allTasksCounter: 0,
      notaryVisitsCounter: 0,
      meetingsCounter: 0,
      clientVisitsCounter: 0,
      deliveryOfPremisesCounter: 0,
      genericTasksCounter: 0,
      openHouseCounter: 0,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  created() {
  },
  mounted() {
    this.groupTasks();
  },
  watch: {
    tasks: {
      handler() {
        this.groupTasks();
      },
      deep: true
    }
  },
  methods: {
    groupTasks() {
      const genericTypes = ["generic", "call", "task", "email", "invoice"]
      this.clearCounters();
      this.tasks.forEach((task) => {
        if (task.category === 'task') {
          if (task.isCompleted) return;
        }
        if (task.type === "notary_meeting") {
          this.notaryVisitsCounter++;
        } else if (task.type === "meeting") {
          this.meetingsCounter++;
        } else if (genericTypes.includes(task.type)) {
          this.genericTasksCounter++;
        } else if (task.type === "delivery_of_premises") {
          this.deliveryOfPremisesCounter++;
        } else if (task.type === "client_visit" || task.type === 'showing_the_object') {
          this.clientVisitsCounter++;
        } else if (task.type === "open_house") {
          this.openHouseCounter++;
        }
        this.allTasksCounter++;
      });

      let notarydWidth =
          (this.notaryVisitsCounter / this.allTasksCounter) * 100;
      let meetingsMadeWidth =
          (this.meetingsCounter / this.allTasksCounter) * 100;
      let genericWidth =
          (this.genericTasksCounter / this.allTasksCounter) * 100;
      let clientVisitsWidth =
          (this.clientVisitsCounter / this.allTasksCounter) * 100;
      let openHouseWidth =
          (this.openHouseCounter / this.allTasksCounter) * 100;
      let deliveryOfPremisesWidth =
          (this.deliveryOfPremisesCounter / this.allTasksCounter) * 100;

      document.getElementById("generic").style.width = genericWidth + "%";
      document.getElementById("meetings").style.width = meetingsMadeWidth + "%";
      document.getElementById("notary").style.width = notarydWidth + "%";
      document.getElementById("clientVisit").style.width =
          clientVisitsWidth + "%";
      document.getElementById("openHouse").style.width =
          openHouseWidth + "%";
      document.getElementById("deliveryOfPremises").style.width =
          deliveryOfPremisesWidth + "%";
    },
    clearCounters() {
      this.allTasksCounter = 0;
      this.notaryVisitsCounter = 0;
      this.meetingsCounter = 0;
      this.clientVisitsCounter = 0;
      this.deliveryOfPremisesCounter = 0;
      this.genericTasksCounter = 0;
      this.openHouseCounter = 0;
    }
  },
};
</script>

<style lang="scss" scoped>
.total-number {
  font-weight: 800;
  font-size: 32px;
  line-height: 32px;
}

.tasks-stages-wrapper {
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  width: 387px;
  height: fit-content;
  display: flex;
  gap: 24px;

  .col {
    padding: 0;
  }
}
.header {
  display: grid;
  align-content: space-between;
}
</style>
