var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "projects-stages-wrapper"
  }, [_c('v-col', {
    staticClass: "header"
  }, [_c('span', [_c('h5', {
    staticStyle: {
      "margin-top": "4px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("ProjectsByStage")) + " ")])]), _c('span', [_c('span', {
    staticClass: "total-number"
  }, [_vm._v(" " + _vm._s(_vm.allDealsCounter) + " ")]), _c('span', {
    staticClass: "gaps10px",
    staticStyle: {
      "color": "#75787a"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Total")) + " ")])])]), _c('v-col', [_c('v-row', {
    staticClass: "content-small",
    staticStyle: {
      "gap": "4px"
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.$t("NewLeads")))]), _c('img', {
    staticClass: "point-separator",
    attrs: {
      "src": require("@/assets/images/•.svg"),
      "alt": ""
    }
  }), _c('p', [_vm._v(_vm._s(_vm.qualifiedCounter))])]), _c('v-row', {
    staticClass: "fill",
    staticStyle: {
      "margin-top": "4px"
    }
  }, [_c('div', {
    staticStyle: {
      "background-color": "#4c8dd9"
    },
    attrs: {
      "id": "qualified"
    }
  })]), _c('v-row', {
    staticClass: "content-small",
    staticStyle: {
      "gap": "4px"
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.$t("NegotiatingRepresentation")))]), _c('img', {
    staticClass: "point-separator",
    attrs: {
      "src": require("@/assets/images/•.svg"),
      "alt": ""
    }
  }), _c('p', [_vm._v(_vm._s(_vm.contactMadeCounter))])]), _c('v-row', {
    staticClass: "fill",
    staticStyle: {
      "margin-top": "4px"
    }
  }, [_c('div', {
    staticStyle: {
      "background-color": "#4c8dd9"
    },
    attrs: {
      "id": "contactMade"
    }
  })]), _c('v-row', {
    staticClass: "content-small",
    staticStyle: {
      "gap": "4px"
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.$t("Preparation")))]), _c('img', {
    staticClass: "point-separator",
    attrs: {
      "src": require("@/assets/images/•.svg"),
      "alt": ""
    }
  }), _c('p', [_vm._v(_vm._s(_vm.preparationCounter))])]), _c('v-row', {
    staticClass: "fill",
    staticStyle: {
      "margin-top": "4px"
    }
  }, [_c('div', {
    staticStyle: {
      "background-color": "#4c8dd9"
    },
    attrs: {
      "id": "preparation"
    }
  })]), _c('v-row', {
    staticClass: "content-small",
    staticStyle: {
      "gap": "4px"
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.$t("ShowingAndAdvertising")))]), _c('img', {
    staticClass: "point-separator",
    attrs: {
      "src": require("@/assets/images/•.svg"),
      "alt": ""
    }
  }), _c('p', [_vm._v(_vm._s(_vm.reviewCounter))])]), _c('v-row', {
    staticClass: "fill",
    staticStyle: {
      "margin-top": "4px"
    }
  }, [_c('div', {
    staticStyle: {
      "background-color": "#4c8dd9"
    },
    attrs: {
      "id": "review"
    }
  })]), _c('v-row', {
    staticClass: "content-small",
    staticStyle: {
      "gap": "4px"
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.$t("FinalizingDeal")))]), _c('img', {
    staticClass: "point-separator",
    attrs: {
      "src": require("@/assets/images/•.svg"),
      "alt": ""
    }
  }), _c('p', [_vm._v(_vm._s(_vm.finishedCounter))])]), _c('v-row', {
    staticClass: "fill",
    staticStyle: {
      "margin-top": "4px"
    }
  }, [_c('div', {
    staticStyle: {
      "background-color": "#4c8dd9"
    },
    attrs: {
      "id": "finished"
    }
  })]), _c('v-row', {
    staticClass: "content-small",
    staticStyle: {
      "gap": "4px"
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.$t("DoneDone")))]), _c('img', {
    staticClass: "point-separator",
    attrs: {
      "src": require("@/assets/images/•.svg"),
      "alt": ""
    }
  }), _c('p', [_vm._v(_vm._s(_vm.doneCounter))])]), _c('v-row', {
    staticClass: "fill-green",
    staticStyle: {
      "margin-top": "4px"
    }
  }, [_c('div', {
    staticStyle: {
      "background-color": "#0aaf60"
    },
    attrs: {
      "id": "done"
    }
  })])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }