var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header"
  }, [_c('v-row', {
    staticClass: "title-row"
  }, [_c('h3', {
    staticClass: "header-title"
  }, [_vm._v(_vm._s(_vm.$t("DashBoard")))])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }