<template>
  <div class="listings-wrapper">
    <v-row style="gap: 16px">
      <img
        style="height: 24px; width: 24px"
        src="@/assets/images/building.svg"
      />
      <h5>
        {{ $t("NewListings") }}
      </h5>
    </v-row>
    <v-row
      v-if="listings.length !== 0"
      class="content-small item-row"
      style="margin-top: 32px; color: #75787a"
    >
      <span style="width: 330px">
        {{ $t("Address") }}
      </span>
      <span style="width: 80px">
        {{ $t("User") }}
      </span>
      <span style="width: 80px">
        {{ $t("Created") }}
      </span>
    </v-row>
    <v-row
        v-if="listings.length !== 0"
        class="separator"
    />
    <v-row
      v-for="listing in listings"
      :key="listing._id"
      class="content-small item-row"
    >
      <span
        style="width: 330px; cursor: pointer"
        @click="openListingPanel(listing._id)"
      >
        {{ listing.listingName }}
      </span>
      <span style="width: 80px">
        {{ listing.creator.name }}
      </span>
      <span style="width: 80px">
        {{ formatDate(listing.createdAt) }}
      </span>
    </v-row>
    <v-row
        class="empty-list subheading"
        v-if="listings.length === 0"
    >
      {{ $t("NoNewListings") }}
    </v-row>
    <ListingSidepanel
      @overlayClicked="closeListingPanel"
      @updateStatuses="updateFromSidepanel"
      v-if="listingPanelOpen"
      :listing-id="chosenListingId"
    />
  </div>
</template>

<script>
import moment from "moment";
import ListingSidepanel from "@/components/Listings/ListingSidepanel";

export default {
  name: "ListingsChart",
  components: {
    ListingSidepanel,
  },
  props: ["listings"],
  data() {
    return {
      chosenListingId: null,
      listingPanelOpen: false,
      statusValues: null,
    };
  },
  created() {},
  computed: {},
  mounted() {},
  watch: {
    statusValues: function () {
      this.listings.forEach((listing) => {
        if (listing._id === this.statusValues.listingId) {
          listing.marketplaces = this.statusValues.marketplaces;
        }
      });
    },
  },
  methods: {
    formatDate(e) {
      return moment(e).format("DD.MM.YYYY");
    },
    openListingPanel(listingId) {
      this.chosenListingId = listingId;
      this.listingPanelOpen = true;
    },
    closeListingPanel() {
      this.listingPanelOpen = false;
    },
    updateFromSidepanel(statusValues) {
      this.statusValues = statusValues;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.empty-list {
  padding: 88px;
  justify-content: center;
}

.item-row {
  gap: 16px;
}
.listings-wrapper {
  background-color: #ffffff;
  width: 570px;
  border-radius: 8px;
  padding: 24px;
}

.separator {
  border-top: 1px solid;
  width: 100%;
  height: 1px;
}
</style>
