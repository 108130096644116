var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "saved-views"
  }, [_c('div', {
    staticClass: "saved-views-header"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/bookmark.svg"),
      "alt": ""
    }
  }), _c('h5', [_vm._v(_vm._s(_vm.$t("SavedViews")))])]), _c('div', {
    staticClass: "saved-views-content"
  }, [_vm.savedViews.length === 0 ? _c('div', {
    staticClass: "empty-list"
  }, [_c('p', {
    staticClass: "subheading"
  }, [_vm._v(_vm._s(_vm.$t("NoSavedViews")))])]) : _vm._e(), _vm._l(_vm.savedViews, function (view, index) {
    return _c('div', {
      key: index,
      staticClass: "saved-view",
      on: {
        "click": function ($event) {
          return _vm.goToView(view);
        }
      }
    }, [_c('div', {
      staticClass: "saved-view-icon-wrapper",
      class: 'view-color-' + view.color
    }, [_c('img', {
      attrs: {
        "src": require(`@/assets/images/` + view.icon),
        "alt": ""
      }
    })]), _c('div', {
      staticClass: "saved-view-title-wrapper"
    }, [_c('div', {
      staticClass: "module-and-menu"
    }, [_c('p', {
      staticClass: "module"
    }, [_vm._v(_vm._s(view.moduleName))]), _c('img', {
      staticClass: "view-edit-menu",
      attrs: {
        "src": require('@/assets/images/dot_menu.svg'),
        "alt": ""
      },
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          view.editorOpen = !view.editorOpen;
        }
      }
    }), view.editorOpen ? _c('SavedViewEditor', {
      attrs: {
        "view": view
      },
      on: {
        "closed": function ($event) {
          view.editorOpen = false;
        },
        "colorChosen": function ($event) {
          return _vm.chooseColor(view, $event);
        },
        "iconChosen": function ($event) {
          return _vm.chooseIcon(view, $event);
        }
      }
    }) : _vm._e()], 1), _c('p', {
      staticClass: "saved-view-title-text content-small content-semibold"
    }, [_vm._v(" " + _vm._s(view.title) + " ")])])]);
  })], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }