var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "projects-wrapper"
  }, [_c('v-row', {
    staticStyle: {
      "gap": "16px"
    }
  }, [_c('img', {
    staticStyle: {
      "height": "24px",
      "width": "24px"
    },
    attrs: {
      "src": require("@/assets/images/building.svg")
    }
  }), _c('h5', [_vm._v(" " + _vm._s(_vm.$t("NewProjects")) + " ")])]), _vm.projects.length !== 0 ? _c('v-row', {
    staticClass: "content-small item-row",
    staticStyle: {
      "margin-top": "32px",
      "color": "#75787a"
    }
  }, [_c('span', {
    staticStyle: {
      "width": "234px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Address")) + " ")]), _c('span', {
    staticStyle: {
      "width": "80px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Type")) + " ")]), _c('span', {
    staticStyle: {
      "width": "80px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("User")) + " ")]), _c('span', {
    staticStyle: {
      "width": "80px"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Created")) + " ")])]) : _vm._e(), _vm.projects.length !== 0 ? _c('v-row', {
    staticClass: "separator"
  }) : _vm._e(), _vm._l(_vm.projects, function (project) {
    return _c('v-row', {
      key: project._id,
      staticClass: "content-small item-row"
    }, [_c('span', {
      staticStyle: {
        "width": "234px",
        "cursor": "pointer"
      },
      on: {
        "click": function ($event) {
          return _vm.openProject(project._id);
        }
      }
    }, [_vm._v(" " + _vm._s(project.name) + " ")]), _c('span', {
      staticStyle: {
        "width": "80px"
      }
    }, [_c('DealTypeContainer', {
      attrs: {
        "small-font": true,
        "deal-type": project.dealType
      }
    })], 1), _c('span', {
      staticStyle: {
        "width": "80px"
      }
    }, [_vm._v(" " + _vm._s(project.creator.name) + " ")]), _c('span', {
      staticStyle: {
        "width": "80px"
      }
    }, [_vm._v(" " + _vm._s(_vm.formatDate(project.createdAt)) + " ")])]);
  }), _vm.projects.length === 0 ? _c('v-row', {
    staticClass: "empty-list subheading"
  }, [_vm._v(" " + _vm._s(_vm.$t("NoNewProjects")) + " ")]) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }